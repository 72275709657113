<template>
  <f7-page class="settings-page">
    <template #fixed>
      <ProfileNavigationComponent :title="$t.getTranslation('LBL_PAYMENT_METHODS')" type="back" :settings="false" />
    </template>

    <section class="payment-container">
      <div class="container">
        <div class="payment-method">
          <div class="image">
            <img src="@/assets/images/paymentmethods/mastercard.png" alt="Payment Method Image" loading="lazy" />
          </div>
          <div class="content">
            <h1>MASTERCARD **** 1234</h1>
            <span class="expires">Expires 01/30</span>

            <f7-button fill>Remove</f7-button>
          </div>
        </div>
        <div class="payment-method">
          <div class="image">
            <img src="@/assets/images/paymentmethods/visa.png" alt="Payment Method Image" loading="lazy" />
          </div>
          <div class="content">
            <h1>VISA **** 1234</h1>
            <span class="expires">Expires 01/30</span>

            <f7-button fill>Remove</f7-button>
          </div>
        </div>
        <div class="payment-method">
          <div class="image">
            <img src="@/assets/images/paymentmethods/paypal.png" alt="Payment Method Image" loading="lazy" />
          </div>
          <div class="content">
            <h1>PAYPAL **** 1234</h1>
            <span class="expires">Expires 01/30</span>

            <f7-button fill>Remove</f7-button>
          </div>
        </div>
      </div>
    </section>
  </f7-page>
</template>

<script>
import { defineComponent, defineAsyncComponent } from "vue";
import { useStore } from "@/store";


// import ProfileNavigationComponent from "@/components/navigations/ProfileNavigationComponent.vue";

const ProfileNavigationComponent = defineAsyncComponent(() => import(/* webpackChunkName: "profile-nav" */ /* webpackMode: "lazy" */ "@/components/navigations/ProfileNavigationComponent.vue"));

export default defineComponent({
  name: "SettingsPaymentPage",
  components: {
    ProfileNavigationComponent,
  },
  props: { f7router: Object },
  setup(props) {
    
    const store = useStore();

    return {};
  },
});
</script>
